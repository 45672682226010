var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.currentEnergyIntake.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Asupan Energi Sekarang (kkal) ")]), _c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Asupan Energi Sekarang (kkal)",
      "height": "62px"
    },
    model: {
      value: _vm.currentEnergyIntake,
      callback: function callback($$v) {
        _vm.currentEnergyIntake = _vm._n($$v);
      },
      expression: "currentEnergyIntake"
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.energyIntakeNeeds.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Asupan Energi (kkal) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Kebutuhan Asupan Energi  (kkal)",
      "height": "62px"
    },
    model: {
      value: _vm.energyIntakeNeeds,
      callback: function callback($$v) {
        _vm.energyIntakeNeeds = _vm._n($$v);
      },
      expression: "energyIntakeNeeds"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.energyAdequancy.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" % Kecukupan Energi ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan % Kecukupan Energi",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.energyAdequancy,
      callback: function callback($$v) {
        _vm.energyAdequancy = _vm._n($$v);
      },
      expression: "energyAdequancy"
    }
  })], 1)], 1), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.energyStatus.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Status Energi ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px",
      "placeholder": "Masukkan Status Energi"
    },
    model: {
      value: _vm.energyStatus,
      callback: function callback($$v) {
        _vm.energyStatus = $$v;
      },
      expression: "energyStatus"
    }
  }, [_c('option', {
    attrs: {
      "value": "less_intake"
    }
  }, [_vm._v(" Asupanmu Kurang ")]), _c('option', {
    attrs: {
      "value": "normal_intake"
    }
  }, [_vm._v(" Asupanmu Normal ")]), _c('option', {
    attrs: {
      "value": "excessive_intake"
    }
  }, [_vm._v(" Asupanmu Berlebih ")])])], 1)], 1) : _vm._e(), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.energyIntakeNotes.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Catatan Asupan Energi ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.energyIntakeNotes,
      expression: "energyIntakeNotes"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "proteinIntakeNotes",
      "rows": "3",
      "placeholder": "Masukkan Catatan Asupan Energi",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.energyIntakeNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.energyIntakeNotes = $event.target.value;
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }