var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Asupan Lemak Sekarang (gram) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Asupan Lemak Sekarang (gram)",
      "height": "62px"
    },
    model: {
      value: _vm.currentFatIntake,
      callback: function callback($$v) {
        _vm.currentFatIntake = _vm._n($$v);
      },
      expression: "currentFatIntake"
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px",
      "margin-right": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "black.900",
      "font-size": "18px"
    }
  }, [_vm._v(" gram ")])], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555",
      "font-weight": "400"
    }
  }, [_vm._v(" Konstanta Kebutuhan Lemak ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px",
      "placeholder": "Pilih Konstanta Kebutuhan Lemak"
    },
    model: {
      value: _vm.fatNeedsConstant,
      callback: function callback($$v) {
        _vm.fatNeedsConstant = $$v;
      },
      expression: "fatNeedsConstant"
    }
  }, [_c('option', {
    attrs: {
      "value": "0.2"
    }
  }, [_vm._v(" 0.2 ")]), _c('option', {
    attrs: {
      "value": "0.25"
    }
  }, [_vm._v(" 0.25 ")]), _c('option', {
    attrs: {
      "value": "0.3"
    }
  }, [_vm._v(" 0.3 ")]), _c('option', {
    attrs: {
      "value": "0.35"
    }
  }, [_vm._v(" 0.35 ")]), _c('option', {
    attrs: {
      "value": "0.4"
    }
  }, [_vm._v(" 0.4 ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Asupan Lemak (gram) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Kebutuhan Asupan Lemak  (gram)",
      "height": "62px"
    },
    model: {
      value: _vm.fatIntakeNeeds,
      callback: function callback($$v) {
        _vm.fatIntakeNeeds = _vm._n($$v);
      },
      expression: "fatIntakeNeeds"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" % Kecukupan Lemak ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan % Kecukupan Lemak",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.fatAdequancy,
      callback: function callback($$v) {
        _vm.fatAdequancy = _vm._n($$v);
      },
      expression: "fatAdequancy"
    }
  })], 1)], 1), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Status Lemak ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px",
      "placeholder": "Masukkan Status Lemak"
    },
    model: {
      value: _vm.fatStatus,
      callback: function callback($$v) {
        _vm.fatStatus = $$v;
      },
      expression: "fatStatus"
    }
  }, [_c('option', {
    attrs: {
      "value": "less_intake"
    }
  }, [_vm._v(" Asupanmu Kurang ")]), _c('option', {
    attrs: {
      "value": "normal_intake"
    }
  }, [_vm._v(" Asupanmu Normal ")]), _c('option', {
    attrs: {
      "value": "excessive_intake"
    }
  }, [_vm._v(" Asupanmu Berlebih ")])])], 1)], 1) : _vm._e(), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Catatan Asupan Lemak ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fatIntakeNotes,
      expression: "fatIntakeNotes"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "lemakIntakeNotes",
      "rows": "3",
      "placeholder": "Masukkan Catatan Asupan Lemak",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.fatIntakeNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.fatIntakeNotes = $event.target.value;
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }