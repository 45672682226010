var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Asupan Karbohidrat Sekarang (gram) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Asupan Karbohidrat Sekarang (gram)",
      "height": "62px"
    },
    model: {
      value: _vm.currentCarbohydrateIntake,
      callback: function callback($$v) {
        _vm.currentCarbohydrateIntake = $$v;
      },
      expression: "currentCarbohydrateIntake"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Asupan Karbohidrat (gram) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Kebutuhan Asupan Karbohidrat  (gram)",
      "height": "62px"
    },
    model: {
      value: _vm.carbohydrateIntakeNeeds,
      callback: function callback($$v) {
        _vm.carbohydrateIntakeNeeds = $$v;
      },
      expression: "carbohydrateIntakeNeeds"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" % Kecukupan Karbohidrat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan % Kecukupan Karbohidrat",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.carbohydrateAdequancy,
      callback: function callback($$v) {
        _vm.carbohydrateAdequancy = $$v;
      },
      expression: "carbohydrateAdequancy"
    }
  })], 1)], 1), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Status Karbohidrat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px",
      "placeholder": "Masukkan Status Karbohidrat"
    },
    model: {
      value: _vm.carbohydrateStatus,
      callback: function callback($$v) {
        _vm.carbohydrateStatus = $$v;
      },
      expression: "carbohydrateStatus"
    }
  }, [_c('option', {
    attrs: {
      "value": "less_intake"
    }
  }, [_vm._v(" Asupanmu Kurang ")]), _c('option', {
    attrs: {
      "value": "normal_intake"
    }
  }, [_vm._v(" Asupanmu Normal ")]), _c('option', {
    attrs: {
      "value": "excessive_intake"
    }
  }, [_vm._v(" Asupanmu Berlebih ")])])], 1)], 1) : _vm._e(), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Catatan Asupan Karbohidrat ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.carbohydrateIntakeNotes,
      expression: "carbohydrateIntakeNotes"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "proteinIntakeNotes",
      "rows": "3",
      "placeholder": "Masukkan Catatan Asupan Karbohidrat",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.carbohydrateIntakeNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.carbohydrateIntakeNotes = $event.target.value;
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }