var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Asupan Protein Sekarang (gram) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Asupan Protein Sekarang (gram)",
      "height": "62px"
    },
    model: {
      value: _vm.currentProteinIntake,
      callback: function callback($$v) {
        _vm.currentProteinIntake = _vm._n($$v);
      },
      expression: "currentProteinIntake"
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px",
      "margin-right": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "black.900",
      "font-size": "18px"
    }
  }, [_vm._v(" gram ")])], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "mb": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555",
      "font-weight": "400"
    }
  }, [_vm._v(" Kebutuhan Protein per kg Berat Badan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "type": "number",
      "height": "62px",
      "placeholder": "Pilih Kebutuhan Protein per kg Berat Badan"
    },
    model: {
      value: _vm.proteinIntakeNeedPerWeight,
      callback: function callback($$v) {
        _vm.proteinIntakeNeedPerWeight = $$v;
      },
      expression: "proteinIntakeNeedPerWeight"
    }
  }, [_c('option', {
    attrs: {
      "value.number": "0.55"
    }
  }, [_vm._v(" 0.55 ")]), _c('option', {
    attrs: {
      "value": "0.6"
    }
  }, [_vm._v(" 0.6 ")]), _c('option', {
    attrs: {
      "value": "0.7"
    }
  }, [_vm._v(" 0.7 ")]), _c('option', {
    attrs: {
      "value": "0.75"
    }
  }, [_vm._v(" 0.75 ")]), _c('option', {
    attrs: {
      "value": "0.8"
    }
  }, [_vm._v(" 0.8 ")]), _c('option', {
    attrs: {
      "value": "0.85"
    }
  }, [_vm._v(" 0.85 ")]), _c('option', {
    attrs: {
      "value": "0.95"
    }
  }, [_vm._v(" 0.95 ")]), _c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(" 1 ")]), _c('option', {
    attrs: {
      "value": "1.1"
    }
  }, [_vm._v(" 1.1 ")]), _c('option', {
    attrs: {
      "value": "1.2"
    }
  }, [_vm._v(" 1.2 ")]), _c('option', {
    attrs: {
      "value": "1.3"
    }
  }, [_vm._v(" 1.3 ")]), _c('option', {
    attrs: {
      "value": "1.35"
    }
  }, [_vm._v(" 1.35 ")]), _c('option', {
    attrs: {
      "value": "1.5"
    }
  }, [_vm._v(" 1.5 ")]), _c('option', {
    attrs: {
      "value": "1.75"
    }
  }, [_vm._v(" 1.75 ")]), _c('option', {
    attrs: {
      "value": "1.8"
    }
  }, [_vm._v(" 1.8 ")]), _c('option', {
    attrs: {
      "value": "1.9"
    }
  }, [_vm._v(" 1.9 ")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v(" 2 ")]), _c('option', {
    attrs: {
      "value": "2.5"
    }
  }, [_vm._v(" 2.5 ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Asupan Protein (gram) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Kebutuhan Asupan Protein  (gram)",
      "height": "62px"
    },
    model: {
      value: _vm.proteinIntakeNeeds,
      callback: function callback($$v) {
        _vm.proteinIntakeNeeds = $$v;
      },
      expression: "proteinIntakeNeeds"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Konstanta Kebutuhan Protein ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Konstanta Kebutuhan Protein",
      "height": "62px"
    },
    model: {
      value: _vm.proteinNeedsConstant,
      callback: function callback($$v) {
        _vm.proteinNeedsConstant = $$v;
      },
      expression: "proteinNeedsConstant"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" % Kecukupan Protein ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan % Kecukupan Protein",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.proteinAdequancy,
      callback: function callback($$v) {
        _vm.proteinAdequancy = $$v;
      },
      expression: "proteinAdequancy"
    }
  })], 1)], 1), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Status Protein ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px",
      "placeholder": "Masukkan Status Protein"
    },
    model: {
      value: _vm.proteinStatus,
      callback: function callback($$v) {
        _vm.proteinStatus = $$v;
      },
      expression: "proteinStatus"
    }
  }, [_c('option', {
    attrs: {
      "value": "less_intake"
    }
  }, [_vm._v(" Asupanmu Kurang ")]), _c('option', {
    attrs: {
      "value": "normal_intake"
    }
  }, [_vm._v(" Asupanmu Normal ")]), _c('option', {
    attrs: {
      "value": "excessive_intake"
    }
  }, [_vm._v(" Asupanmu Berlebih ")])])], 1)], 1) : _vm._e(), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Catatan Asupan Protein ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.proteinIntakeNotes,
      expression: "proteinIntakeNotes"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "proteinIntakeNotes",
      "rows": "3",
      "placeholder": "Masukkan Catatan Asupan Protein",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.proteinIntakeNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.proteinIntakeNotes = $event.target.value;
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }