<template>
  <c-box>
    <!-- Asupan Sekarang -->
    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Asupan Protein Sekarang (gram)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="currentProteinIntake"
          type="number"
          placeholder="Masukkan Asupan Protein Sekarang (gram)"
          height="62px"
        />
        <c-input-right-element
          width="4.5rem"
          mt="10px"
          margin-right="20px"
        >
          <c-text
            color="black.900"
            font-size="18px"
          >
            gram
          </c-text>
        </c-input-right-element>
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      mb="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555"
        font-weight="400"
      >
        Kebutuhan Protein per kg Berat Badan
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="proteinIntakeNeedPerWeight"
          type="number"
          height="62px"
          placeholder="Pilih Kebutuhan Protein per kg Berat Badan"
        >
          <option value.number="0.55">
            0.55
          </option>
          <option value="0.6">
            0.6
          </option>
          <option value="0.7">
            0.7
          </option>
          <option value="0.75">
            0.75
          </option>
          <option value="0.8">
            0.8
          </option>
          <option value="0.85">
            0.85
          </option>
          <option value="0.95">
            0.95
          </option>
          <option value="1">
            1
          </option>
          <option value="1.1">
            1.1
          </option>
          <option value="1.2">
            1.2
          </option>
          <option value="1.3">
            1.3
          </option>
          <option value="1.35">
            1.35
          </option>
          <option value="1.5">
            1.5
          </option>
          <option value="1.75">
            1.75
          </option>
          <option value="1.8">
            1.8
          </option>
          <option value="1.9">
            1.9
          </option>
          <option value="2">
            2
          </option>
          <option value="2.5">
            2.5
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--Kebutuhan Asupan-->
    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kebutuhan Asupan Protein (gram)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="proteinIntakeNeeds"
          type="number"
          placeholder="Masukkan Kebutuhan Asupan Protein  (gram)"
          height="62px"
        />
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Konstanta Kebutuhan Protein
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="proteinNeedsConstant"
          type="number"
          placeholder="Masukkan Konstanta Kebutuhan Protein"
          height="62px"
        />
      </c-input-group>
    </c-form-control>

    <!--% Kecukupan-->
    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        % Kecukupan Protein
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="proteinAdequancy"
          type="number"
          placeholder="Masukkan % Kecukupan Protein"
          height="62px"
          padding-right="60px"
        />
      </c-input-group>
    </c-form-control>

    <!--Status Protein-->
    <c-form-control
      v-if="withoutAdds === false"
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Status Protein
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="proteinStatus"
          height="62px"
          placeholder="Masukkan Status Protein"
        >
          <!--ENUM('less_intake', 'normal_intake', 'excessive_intake')-->
          <option value="less_intake">
            Asupanmu Kurang
          </option>
          <option value="normal_intake">
            Asupanmu Normal
          </option>
          <option value="excessive_intake">
            Asupanmu Berlebih
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--Catatan-->
    <c-form-control
      v-if="withoutAdds === false"
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Catatan Asupan Protein
      </c-form-label>
      <textarea
        id="proteinIntakeNotes"
        v-model="proteinIntakeNotes"
        v-chakra
        rows="3"
        placeholder="Masukkan Catatan Asupan Protein"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
      />
    </c-form-control>
  </c-box>
</template>

<script>
import equal from 'fast-deep-equal'
import {
  calculateKebutuhanAsupanProtein,
  calculateKecukupanProtein,
  calculateKonstantaKebutuhanProtein,
  calculateStatus,
} from '@/utils/asupan-gizi-calculation'

export default {
  name: 'ProteinForm',
  props: {
    weight: {
      required: true,
      type: Number,
    },
    kebutuhanAsupanEnergi: {
      required: true,
      type: Number,
    },
    withoutAdds: Boolean,
    value: {
      required: true,
      type: Object,
      _type: {
        currentProteinIntake: Number,
        proteinNeedsConstant: Number,
        proteinIntakeNeedPerWeight: Number,
        proteinIntakeNeeds: Number,
        proteinAdequancy: Number,
        proteinStatus: String,
        proteinIntakeNotes: String,
      },
    },
  },
  data() {
    return {
      currentProteinIntake: this.value.currentProteinIntake,
      proteinNeedsConstant: this.value.proteinNeedsConstant,
      proteinIntakeNeedPerWeight: this.value.proteinIntakeNeedPerWeight,
      proteinIntakeNeeds: this.value.proteinIntakeNeeds,
      proteinAdequancy: this.value.proteinAdequancy,
      proteinStatus: this.value.proteinStatus,
      proteinIntakeNotes: this.value.proteinIntakeNotes,
    }
  },
  computed: {
    _proteinIntakeNeeds() {
      return calculateKebutuhanAsupanProtein({
        weight: this.weight,
        kebutuhanProteinPerWeight: this.proteinIntakeNeedPerWeight,
      })
    },
    _proteinNeedsConstant() {
      let data = calculateKonstantaKebutuhanProtein({
        kebutuhanAsupanEnergi: this.kebutuhanAsupanEnergi,
        kebutuhanAsupanProtein: this.proteinIntakeNeeds,
      })

      return parseFloat((data * 100).toFixed(2))
    },
    _proteinAdequancy() {
      let data = calculateKecukupanProtein({
        asupanProteinSeharusnya: this.proteinIntakeNeeds,
        asupanProteinSekarang: this.currentProteinIntake,
      })
      return parseFloat((data * 100).toFixed(2))
    },
    _proteinStatus() {
      return calculateStatus(this.proteinAdequancy / 100)
    },
    _value() {
      return {
        currentProteinIntake: this.currentProteinIntake,
        proteinNeedsConstant: this.proteinNeedsConstant,
        proteinIntakeNeedPerWeight: this.proteinIntakeNeedPerWeight,
        proteinIntakeNeeds: this.proteinIntakeNeeds,
        proteinAdequancy: this.proteinAdequancy,
        proteinStatus: this.proteinStatus,
        proteinIntakeNotes: this.proteinIntakeNotes,
      }
    },
  },
  watch: {
    _proteinIntakeNeeds(v1, v2) {
      if (equal(v1, v2)) return
      this.proteinIntakeNeeds = v1
    },
    _proteinNeedsConstant(v1, v2) {
      if (equal(v1, v2)) return
      this.proteinNeedsConstant = v1
    },
    _proteinAdequancy(v1, v2) {
      if (equal(v1, v2)) return
      this.proteinAdequancy = v1
    },
    _proteinStatus(v1, v2) {
      if (equal(v1, v2)) return
      this.proteinStatus = v1
    },
    value(val) {
      if (this.ifEmpty(this.currentProteinIntake)) {
        this.currentProteinIntake = val.currentProteinIntake
      }
      if (this.ifEmpty(this.proteinNeedsConstant)) {
        this.proteinNeedsConstant = val.proteinNeedsConstant
      }
      if (this.ifEmpty(this.proteinIntakeNeedPerWeight)) {
        this.proteinIntakeNeedPerWeight = val.proteinIntakeNeedPerWeight
      }
      if (this.ifEmpty(this.proteinIntakeNeeds)) {
        this.proteinIntakeNeeds = val.proteinIntakeNeeds
      }
      if (this.ifEmpty(this.proteinAdequancy)) {
        this.proteinAdequancy = val.proteinAdequancy
      }
      if (this.ifEmpty(this.proteinIntakeNotes)) {
        this.proteinIntakeNotes = val.proteinIntakeNotes
      }
      if (this.ifEmpty(this.proteinStatus)) {
        this.proteinStatus = val.proteinStatus
      }
    },
    _value: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    ifEmpty(val) {
      return val == null || val === ''
    },
  },
}
</script>
