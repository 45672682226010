var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Asupan Serat Sekarang (gram) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Asupan Serat Sekarang (gram)",
      "height": "62px"
    },
    model: {
      value: _vm.currentFiberIntake,
      callback: function callback($$v) {
        _vm.currentFiberIntake = $$v;
      },
      expression: "currentFiberIntake"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Asupan Serat (gram) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px",
      "placeholder": "Masukkan Kebutuhan Asupan Serat (gram)"
    },
    model: {
      value: _vm.fiberIntakeNeeds,
      callback: function callback($$v) {
        _vm.fiberIntakeNeeds = $$v;
      },
      expression: "fiberIntakeNeeds"
    }
  }, [_c('option', {
    attrs: {
      "value": "32"
    }
  }, [_vm._v(" Perempuan Dewasa (32) ")]), _c('option', {
    attrs: {
      "value": "37"
    }
  }, [_vm._v(" Laki-laki Dewasa / Ibu Hamil atau Menyusui (37) ")]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v(" Perempuan / Laki-laki Lansia (25) ")]), _c('option', {
    attrs: {
      "value": "29"
    }
  }, [_vm._v(" Perempuan Remaja (29) ")]), _c('option', {
    attrs: {
      "value": "34"
    }
  }, [_vm._v(" Laki-Laki Remaja (34) ")]), _c('option', {
    attrs: {
      "value": "19"
    }
  }, [_vm._v(" Anak Dibawah 3 tahun (19) ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" Anak 4 - 6 Tahun (20) ")]), _c('option', {
    attrs: {
      "value": "23"
    }
  }, [_vm._v(" Anak 7 - 9 Tahun (23) ")])])], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" % Kecukupan Serat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan % Kecukupan Serat",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.fiberAdequancy,
      callback: function callback($$v) {
        _vm.fiberAdequancy = $$v;
      },
      expression: "fiberAdequancy"
    }
  })], 1)], 1), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Status Serat ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "height": "62px",
      "placeholder": "Masukkan Status Serat"
    },
    model: {
      value: _vm.fiberStatus,
      callback: function callback($$v) {
        _vm.fiberStatus = $$v;
      },
      expression: "fiberStatus"
    }
  }, [_c('option', {
    attrs: {
      "value": "less_intake"
    }
  }, [_vm._v(" Asupanmu Kurang ")]), _c('option', {
    attrs: {
      "value": "normal_intake"
    }
  }, [_vm._v(" Asupanmu Normal ")]), _c('option', {
    attrs: {
      "value": "excessive_intake"
    }
  }, [_vm._v(" Asupanmu Berlebih ")])])], 1)], 1) : _vm._e(), _vm.withoutAdds === false ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Catatan Asupan Serat ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fiberIntakeNotes,
      expression: "fiberIntakeNotes"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "proteinIntakeNotes",
      "rows": "3",
      "placeholder": "Masukkan Catatan Asupan Serat",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.fiberIntakeNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.fiberIntakeNotes = $event.target.value;
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }