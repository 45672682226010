<template>
  <c-box>
    <!-- Asupan Sekarang -->
    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Asupan Lemak Sekarang (gram)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="currentFatIntake"
          type="number"
          placeholder="Masukkan Asupan Lemak Sekarang (gram)"
          height="62px"
        />
        <c-input-right-element
          width="4.5rem"
          mt="10px"
          margin-right="20px"
        >
          <c-text
            color="black.900"
            font-size="18px"
          >
            gram
          </c-text>
        </c-input-right-element>
      </c-input-group>
    </c-form-control>

    <c-form-control
      is-required
      mb="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555"
        font-weight="400"
      >
        Konstanta Kebutuhan Lemak
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="fatNeedsConstant"
          height="62px"
          placeholder="Pilih Konstanta Kebutuhan Lemak"
        >
          <option value="0.2">
            0.2
          </option>
          <option value="0.25">
            0.25
          </option>
          <option value="0.3">
            0.3
          </option>
          <option value="0.35">
            0.35
          </option>
          <option value="0.4">
            0.4
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--Kebutuhan Asupan-->
    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kebutuhan Asupan Lemak (gram)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="fatIntakeNeeds"
          type="number"
          placeholder="Masukkan Kebutuhan Asupan Lemak  (gram)"
          height="62px"
        />
      </c-input-group>
    </c-form-control>

    <!--% Kecukupan-->
    <c-form-control
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        % Kecukupan Lemak
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="fatAdequancy"
          type="number"
          placeholder="Masukkan % Kecukupan Lemak"
          height="62px"
          padding-right="60px"
        />
      </c-input-group>
    </c-form-control>

    <!--Status Lemak-->
    <c-form-control
      v-if="withoutAdds === false"
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Status Lemak
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="fatStatus"
          height="62px"
          placeholder="Masukkan Status Lemak"
        >
          <!--ENUM('less_intake', 'normal_intake', 'excessive_intake')-->
          <option value="less_intake">
            Asupanmu Kurang
          </option>
          <option value="normal_intake">
            Asupanmu Normal
          </option>
          <option value="excessive_intake">
            Asupanmu Berlebih
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--Catatan-->
    <c-form-control
      v-if="withoutAdds === false"
      is-required
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Catatan Asupan Lemak
      </c-form-label>
      <textarea
        id="lemakIntakeNotes"
        v-model="fatIntakeNotes"
        v-chakra
        rows="3"
        placeholder="Masukkan Catatan Asupan Lemak"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
      />
    </c-form-control>
  </c-box>
</template>

<script>
import equal from 'fast-deep-equal'
import {
  calculateKebutuhanAsupanLemak,
  calculateKecukupanLemak,
  calculateStatus,
} from '@/utils/asupan-gizi-calculation'

export default {
  name: 'LemakForm',
  props: {
    kebutuhanAsupanEnergi: {
      required: true,
      type: Number,
    },
    withoutAdds: Boolean,
    value: {
      required: true,
      type: Object,
      _type: {
        currentFatIntake: Number,
        fatNeedsConstant: Number,
        fatIntakeNeeds: Number,
        fatAdequancy: Number,
        fatStatus: String,
        fatIntakeNotes: String,
      },
    },
  },
  data() {
    return {
      currentFatIntake: this.value.currentFatIntake,
      fatNeedsConstant: this.value.fatNeedsConstant,
      fatIntakeNeeds: this.value.fatIntakeNeeds,
      fatAdequancy: this.value.fatAdequancy,
      fatStatus: this.value.fatStatus,
      fatIntakeNotes: this.value.fatIntakeNotes,
    }
  },
  computed: {
    _fatIntakeNeeds() {
      let data = calculateKebutuhanAsupanLemak({
        kebutuhanAsupanEnergi: +this.kebutuhanAsupanEnergi,
        konstantaKebutuhanLemak: +this.fatNeedsConstant,
      })

      return +data.toFixed(2)
    },
    _fatAdequancy() {
      let data = calculateKecukupanLemak({
        asupanLemakSekarang: +this.currentFatIntake,
        kebutuhanAsupanLemak: +this.fatIntakeNeeds,
      })

      return +((data * 100).toFixed(2))
    },
    _fatStatus() {
      return calculateStatus(this.fatAdequancy / 100)
    },
    _value() {
      return {
        currentFatIntake: this.currentFatIntake,
        fatNeedsConstant: this.fatNeedsConstant,
        fatIntakeNeeds: this.fatIntakeNeeds,
        fatAdequancy: this.fatAdequancy,
        fatStatus: this.fatStatus,
        fatIntakeNotes: this.fatIntakeNotes,
      }
    },
  },
  watch: {
    _fatIntakeNeeds(v1, v2) {
      if (equal(v1, v2)) return
      this.fatIntakeNeeds = v1
    },
    _fatAdequancy(v1, v2) {
      if (equal(v1, v2)) return
      this.fatAdequancy = v1
    },
    _fatStatus(v1, v2) {
      if (equal(v1, v2)) return
      this.fatStatus = v1
    },
    value(val) {
      this.currentFatIntake = val.currentFatIntake
      this.fatNeedsConstant = val.fatNeedsConstant
      this.fatIntakeNeeds = val.fatIntakeNeeds
      this.fatAdequancy = val.fatAdequancy
      this.fatStatus = val.fatStatus
      this.fatIntakeNotes = val.fatIntakeNotes
    },
    _value: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
