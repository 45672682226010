export function calculateStatus(item) {
  // <!--ENUM('less_intake', 'normal_intake', 'excessive_intake')-->
  if (item < 0.8) return 'less_intake'
  if (item >= 0.8 && item <= 1.1) return 'normal_intake'
  if (item > 1.1) return 'excessive_intake'
}

export function calculateKebutuhanAsupanEnergi({
  aktivitasFisik,
  kebutuhanEnergi,
  tambahanKalori,
  defisitKalori,
}) {
  // (Kebutuhan Energi Bassal + (Kebutuhan Energi Bassal x % Aktivitas Fisik) + Kondisi Keibuan - Defisit kalori yang diberikan
  let result = kebutuhanEnergi + (kebutuhanEnergi * (aktivitasFisik / 100)) + tambahanKalori - defisitKalori

  return result
  // return (
  //   kebutuhanEnergi +
  //   kebutuhanEnergi * aktivitasFisik +
  //   tambahanKalori -
  //   defisitKalori
  // );
}

export function calculateKecukupanEnergi({
  asupanEnergiSekarang,
  kebutuhanAsupanEnergi,
}) {
  // Asupan Energi Sekarang/Kebutuhan Asupan Energi
  return asupanEnergiSekarang / kebutuhanAsupanEnergi
}

export function calculateStatusEnergi(kecukupanEnergi) {
  /*
  Kategori Status Energi:
  1. Jika < 80% --> Asupanmu kurang
  2. Jika >= 80% - 110% --> Asupanmu cukup
  3. Jika > 110% --> Asupanmu berlebih
   */
  return calculateStatus(kecukupanEnergi)
}

export function calculateKebutuhanAsupanProtein({
  weight,
  kebutuhanProteinPerWeight,
}) {
  // (Berat badan * Kebutuhan Protein per Kg Berat Badan)
  return weight * kebutuhanProteinPerWeight
}

export function calculateKonstantaKebutuhanProtein({
  kebutuhanAsupanEnergi,
  kebutuhanAsupanProtein,
}) {
  // [(Kebutuhan Asupan Protein x 4)/Kebutuhan Asupan Energi]
  return (kebutuhanAsupanProtein * 4) / kebutuhanAsupanEnergi
}

export function calculateKecukupanProtein({
  asupanProteinSekarang,
  asupanProteinSeharusnya,
}) {
  // Asupan Protein Sekarang / Asupan Protein Seharusnya
  return asupanProteinSekarang / asupanProteinSeharusnya
}

export function calculateStatusProtein(kecukupanProtein) {
  /*
  Kategori Status Protein
  1. Jika < 80% --> Asupanmu Kurang
  2. Jika >= 80% - 110% --> Asupanmu cukup
  3. Jika > 110% --> Asupanmu berlebih
   */

  return calculateStatus(kecukupanProtein)
}

export function calculateKebutuhanAsupanLemak({
  kebutuhanAsupanEnergi,
  konstantaKebutuhanLemak,
}) {
  // (Kebutuhan Asupan Energi x Konstanta Kebutuhan Lemak) / 9
  return (kebutuhanAsupanEnergi * konstantaKebutuhanLemak) / 9
}
export function calculateKecukupanLemak({
  asupanLemakSekarang,
  kebutuhanAsupanLemak,
}) {
  // Asupan Lemak Sekarang / Kebutuhan Asupan Lemak
  return asupanLemakSekarang / kebutuhanAsupanLemak
}
export function calculateStatusLemak(kecukupanLemak) {
  /*
  Kategori Status Lemak
  1. Jika < 80% --> Asupanmu Kurang
  2. Jika >= 80% - 110% --> Asupanmu cukup
  3. Jika > 110% --> Asupanmu berlebih
   */
  return calculateStatus(kecukupanLemak)
}

// --- Karbohidrat
export function calculateKebutuhanAsupanKarbohidrat({
  kebutuhanAsupanEnergi,
  kebutuhanProtein,
  kebutuhanLemak,
}) {
  // [Kebutuhan Asupan Energi x (1- Konstanta Kebutuhan Protein - Konstanta Kebutuhan Lemak)] / 4
  return (kebutuhanAsupanEnergi * (1 - kebutuhanProtein - kebutuhanLemak)) / 4
}
export function calculateKecukupanKarbohidrat({
  asupanKarbohidratSekarang,
  kebutuhanAsupanKarbohidrat,
}) {
  // Asupan Karbohidrat Sekarang / Kebutuhan Asupan Karbohidrat
  return asupanKarbohidratSekarang / kebutuhanAsupanKarbohidrat
}
export function calculateStatusKarbohidrat(kecukupanKarbohidrat) {
  return calculateStatus(kecukupanKarbohidrat)
}

// --- Serat
export function calculateKecukupanSerat({
  asupanSeratSekarang,
  kebutuhanAsupanSerat,
}) {
  // Asupan Serat Sekarang / Kebutuhan Asupan Serat
  return asupanSeratSekarang / kebutuhanAsupanSerat
}
