import { render, staticRenderFns } from "./lemak-form.vue?vue&type=template&id=0f0a00c6&"
import script from "./lemak-form.vue?vue&type=script&lang=js&"
export * from "./lemak-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CText: require('@chakra-ui/vue').CText, CInputRightElement: require('@chakra-ui/vue').CInputRightElement, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CSelect: require('@chakra-ui/vue').CSelect, CBox: require('@chakra-ui/vue').CBox})
