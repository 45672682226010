<template>
  <c-box>
    <!-- Asupan Sekarang -->
    <c-form-control
      is-required
      :is-invalid="$v.currentEnergyIntake.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Asupan Energi Sekarang (kkal)
      </c-form-label>
      <c-input
        v-model.number="currentEnergyIntake"
        type="number"
        placeholder="Masukkan Asupan Energi Sekarang (kkal)"
        height="62px"
      />
    </c-form-control>

    <!--Kebutuhan Asupan-->
    <c-form-control
      is-required
      :is-invalid="$v.energyIntakeNeeds.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kebutuhan Asupan Energi (kkal)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="energyIntakeNeeds"
          type="number"
          placeholder="Masukkan Kebutuhan Asupan Energi  (kkal)"
          height="62px"
        />
      </c-input-group>
    </c-form-control>

    <!--% Kecukupan-->
    <c-form-control
      is-required
      :is-invalid="$v.energyAdequancy.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        % Kecukupan Energi
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="energyAdequancy"
          type="number"
          placeholder="Masukkan % Kecukupan Energi"
          height="62px"
          padding-right="60px"
        />
      </c-input-group>
    </c-form-control>

    <!--Status Energi-->
    <c-form-control
      v-if="withoutAdds === false"
      is-required
      :is-invalid="$v.energyStatus.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Status Energi
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="energyStatus"
          height="62px"
          placeholder="Masukkan Status Energi"
        >
          <!--ENUM('less_intake', 'normal_intake', 'excessive_intake')-->
          <option value="less_intake">
            Asupanmu Kurang
          </option>
          <option value="normal_intake">
            Asupanmu Normal
          </option>
          <option value="excessive_intake">
            Asupanmu Berlebih
          </option>
        </c-select>
      </c-input-group>
    </c-form-control>

    <!--Catatan-->
    <c-form-control
      v-if="withoutAdds === false"
      is-required
      :is-invalid="$v.energyIntakeNotes.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Catatan Asupan Energi
      </c-form-label>
      <textarea
        id="proteinIntakeNotes"
        v-model="energyIntakeNotes"
        v-chakra
        rows="3"
        placeholder="Masukkan Catatan Asupan Energi"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
      />
    </c-form-control>
  </c-box>
</template>

<script>
import {
  calculateKebutuhanAsupanEnergi,
  calculateKecukupanEnergi,
  calculateStatus,
} from '@/utils/asupan-gizi-calculation'
import equal from 'fast-deep-equal'
import required from 'vuelidate/lib/validators/required'

export default {
  name: 'EnergiForm',
  props: {
    aktivitasFisik: {
      type: Number,
      required: true,
    },
    kebutuhanEnergi: {
      type: Number,
      required: true,
    },
    defisitKalori: {
      type: Number,
      required: true,
    },
    tambahanKalori: {
      type: Number,
      required: true,
    },
    withoutAdds: Boolean,
    value: {
      required: true,
      type: Object,
      _type: {
        currentEnergyIntake: Number,
        energyIntakeNeeds: Number,
        energyAdequancy: Number,
        energyStatus: String,
        energyIntakeNotes: String,
      },
    },
  },
  data() {
    return {
      currentEnergyIntake: null,
      energyIntakeNeeds: null,
      energyAdequancy: null,
      energyStatus: null,
      energyIntakeNotes: null,
    }
  },
  computed: {
    // Kebutuhan Asupan Energi
    _energyIntakeNeeds() {
      return calculateKebutuhanAsupanEnergi({
        aktivitasFisik: this.aktivitasFisik,
        kebutuhanEnergi: this.kebutuhanEnergi,
        defisitKalori: this.defisitKalori,
        tambahanKalori: this.tambahanKalori,
      })
    },
    _energyAdequancy() {
      let data = calculateKecukupanEnergi({
        asupanEnergiSekarang: this.currentEnergyIntake,
        kebutuhanAsupanEnergi: this._energyIntakeNeeds ?? 0,
      })
      return parseFloat((data * 100).toFixed())
    },
    _energyStatus() {
      let percent = this._energyAdequancy / 100
      return calculateStatus(percent)
    },
    _value() {
      return {
        currentEnergyIntake: this.currentEnergyIntake,
        energyIntakeNeeds: this.energyIntakeNeeds,
        energyAdequancy: this.energyAdequancy,
        energyStatus: this.energyStatus,
        energyIntakeNotes: this.energyIntakeNotes,
      }
    },
  },
  watch: {
    _energyIntakeNeeds(v1, v2) {
      if (equal(v1, v2)) return
      this.energyIntakeNeeds = this._energyIntakeNeeds
    },
    _energyAdequancy(v1, v2) {
      if (equal(v1, v2)) return
      this.energyAdequancy = this._energyAdequancy
    },
    _energyStatus(v1, v2) {
      if (equal(v1, v2)) return
      this.energyStatus = this._energyStatus
    },
    value: {
      immediate: true,
      handler(val) {
        this.currentEnergyIntake = val.currentEnergyIntake
        this.energyIntakeNeeds = val.energyIntakeNeeds
        this.energyAdequancy = val.energyAdequancy
        this.energyStatus = val.energyStatus
        this.energyIntakeNotes = val.energyIntakeNotes
      },
    },
    _value: {
      deep: true,
      handler(val, old) {
        if (equal(val, old)) return
        this.$emit('input', val)
      },
    },
  },
  methods: {
  },
  validations: {
    currentEnergyIntake: { required },
    energyIntakeNeeds: { required },
    energyAdequancy: { required },
    energyStatus: { required },
    energyIntakeNotes: { required },
  },
}
</script>
